<template>

<section>
                
       
        
        <div v-for="(producto) in productos" :key="producto.id">
          <div class="container_detalle" v-if="producto.id == currentRouteName"> 
            <router-link to="/productos" class="back_to_list"><h4> &lt; Volver al listado </h4></router-link>
            <div class="detalle">
              <aside class="asideDetalle">
                          <ul class="thumbnails">
                              <li @mouseover="updateProduct(producto.thumbnail_1)"><img :src="producto.thumbnail_1" :alt="producto.title"> </li>
                              <li @mouseover="updateProduct(producto.thumbnail_2)"><img :src="producto.thumbnail_2" :alt="producto.title"> </li>
                              <li @mouseover="updateProduct(producto.thumbnail_3)"><img :src="producto.thumbnail_3" :alt="producto.title"> </li>
                          </ul>
                          
                          <img class="image_detailed" :src="producto.image" alt="">
              </aside>
              <article class="detalle_producto">
                      <form action="">
                          <h1>{{ producto.nombre }}</h1>
                          
                          <ul class="caracteristicas">
                            <label for="">Medida</label>
                            <li>{{ producto.medida }}</li>

                            <label for="">Calibre</label>
                            <li>{{ producto.calibre }}</li>

                            <label for="">Gramaje</label>
                            <li>{{ producto.gramaje }}</li>

                            <label for="">Caracteristicas</label>
                            <li>{{ producto.caracteristicas }}</li>

                            <label for="">Colores</label>
                            <li>{{ producto.colores }}</li>
                            <!-- <form action="https://wa.link/be1jxx">
                             <input class="button" type="submit" value="Cotizar producto" />
                            </form>   -->
                              <div class="cotizar">
                              <a href="https://wa.link/be1jxx" target="blank" class="button">Cotizar producto</a>  
                              </div>
                            </ul> <br>
                          
                          
                      </form>
                  </article>
                </div>
          </div>
        </div>
       
       
  
<!-- v-if="(perro.id)=($route.params.id)" -->
            
  </section>
     
       
   
</template>

<script>
import Product from "@/components/Component_product"
import Products from "@/components/Component_products"

export default {
    components: {
        Product,
        Products
    },
    props: ['detalle', 'material' ,'image', 'thumbnail_1', 'thumbnail_2', 'thumbnail_3', 'title', 'category', 'price'],
    data() {
    return{
     productos: [
        { 
          id: 1, 
          nombre: 'Aceite siliconado para maquina', 
          categoria: 'otros', 
          medida:'Disponible en 250 ML, 1/4 de Galon, medio galon, galon', 
          calibre:'No aplica', 
          gramaje:'No aplica',  
          caracteristicas:'ACEITE SILICONADO PARA MAQUINA INDUSTRIAL , NO MANCHA LAS PRENDAS.', 
          colores:'No aplica',
          image:'assets/images/fotos/aceite.jpg',
          thumbnail:'assets/images/miniaturas/aceite.jpg',
          thumbnail_1: '', 
          thumbnail_2: '', 
          thumbnail_3: ''
        },
        { 
          id: 2, 
          nombre: 'Agujas para maquina collarin', 
          categoria: 'otros', 
          medida:'Disponible en 70/10, 80/12, 90/14, 100/16, 110/18', 
          calibre:'No aplica', 
          gramaje:'No aplica',  
          caracteristicas:'PUNTA DE BOLA', 
          colores:'No aplica',
          image:'assets/images/fotos/agujas_collarin.jpg',
          thumbnail:'assets/images/miniaturas/agujas_collarin.jpg',
          thumbnail_1: '', 
          thumbnail_2: '', 
          thumbnail_3: ''
        },

        { 
          id: 3, 
          nombre: 'Agujas para maquina filete', 
          categoria: 'otros', 
          medida:'Disponible en 70/10, 80/12, 90/14, 100/16, 110/18', 
          calibre:'No aplica', 
          gramaje:'No aplica',  
          caracteristicas:'PUNTA DE BOLA', 
          colores:'No aplica',
          image:'assets/images/fotos/agujas_filete.jpg',
          thumbnail:'assets/images/miniaturas/agujas_filete.jpg',
          thumbnail_1: '', 
          thumbnail_2: '', 
          thumbnail_3: ''
        },
        { 
          id: 4, 
          nombre: 'agujas para maquina plana, cubo delgado', 
          categoria: 'otros', 
          medida:'Disponible en 70/10, 80/12, 90/14, 100/16, 110/18', 
          calibre:'No aplica', 
          gramaje:'No aplica',  
          caracteristicas:'PUNTA DE BOLA', 
          colores:'No aplica',
          image:'assets/images/fotos/agujas_maquina_plana_cubo_delgado.jpg',
          thumbnail:'assets/images/miniaturas/agujas_maquina_plana_cubo_delgado.jpg',
          thumbnail_1: '', 
          thumbnail_2: '', 
          thumbnail_3: ''
        },
        { 
          id: 5, 
          nombre: 'Agujas para maquina plana, cubo grueso', 
          categoria: 'otros', 
          medida:'Disponible en 70/10, 80/12, 90/14, 100/16, 110/18', 
          calibre:'No aplica', 
          gramaje:'No aplica',  
          caracteristicas:'PUNTA DE BOLA', 
          colores:'No aplica',
          image:'assets/images/fotos/agujas_maquina_plana_cubo_grueso.jpg',
          thumbnail:'assets/images/miniaturas/agujas_maquina_plana_cubo_grueso.jpg',
          thumbnail_1: '', 
          thumbnail_2: '', 
          thumbnail_3: ''
        },
        
        { 
          id: 6, 
          nombre: 'Plastiflechas trabajo liviano.', 
          categoria: 'otros', 
          medida:'1 PULGADA', 
          calibre:'No aplica', 
          gramaje:'No aplica',  
          caracteristicas:'Platiflechas trabajo liviano,caja x 5000 unidades', 
          colores:'No aplica',
          image:'assets/images/fotos/plastiflechas_trabajo_liviano.jpg',
          thumbnail:'assets/images/miniaturas/plastiflechas_trabajo_liviano.jpg',
          thumbnail_1: '', 
          thumbnail_2: '', 
          thumbnail_3: ''
        },
        
        { 
          id: 7, 
          nombre: 'Rematador metálico.', 
          categoria: 'otros', 
          medida:'No aplica', 
          calibre:'No aplica', 
          gramaje:'No aplica',  
          caracteristicas:'Se vende por unidad o paquete por 3 unidades', 
          colores:'No aplica',
          image:'assets/images/fotos/rematador_metalico.jpg',
          thumbnail:'assets/images/miniaturas/rematador_metalico.jpg',
          thumbnail_1: 'assets/images/fotos/rematador_metalico.jpg', 
          thumbnail_2: 'assets/images/fotos/rematador_metalico_2.jpg', 
          thumbnail_3: ''
        },
        { 
          id: 8, 
          nombre: 'Rematador plastico', 
          categoria: 'otros', 
          medida:'No aplica', 
          calibre:'No aplica', 
          gramaje:'No aplica',  
          caracteristicas:'Se vende por unidad o paquete por 3 unidades', 
          colores:'rojo, verde',
          image:'assets/images/fotos/rematador_plastico.jpg',
          thumbnail:'assets/images/miniaturas/rematador_plastico.jpg',
          thumbnail_1: '', 
          thumbnail_2: '', 
          thumbnail_3: ''
        },
        { 
          id: 9, 
          nombre: 'Rematador plastico de oreja', 
          categoria: 'otros', 
          medida:'No aplica', 
          calibre:'No aplica', 
          gramaje:'No aplica',  
          caracteristicas:'No aplica', 
          colores:'Verde, Gris, lila',
          image:'assets/images/fotos/rematador_plastico_de_oreja.jpg',
          thumbnail:'assets/images/miniaturas/rematador_plastico_de_oreja.jpg',
          thumbnail_1: 'assets/images/fotos/rematador_plastico_de_oreja.jpg', 
          thumbnail_2: 'assets/images/fotos/rematador_plastico_de_oreja_2.jpg', 
          thumbnail_3: ''
        },
        { 
          id: 10, 
          nombre: 'Tijera Las Pima', 
          categoria: 'otros', 
          medida:'5.5 pulgadas, 8.5 pulgadas', 
          calibre:'No aplica', 
          gramaje:'No aplica',  
          caracteristicas:'No aplica', 
          colores:'Verde, Gris, lila',
          image:'assets/images/fotos/Tijera_Las_Pima_de_5-5_pulgadas.jpg',
          thumbnail:'assets/images/miniaturas/Tijera_Las_Pima_de_5-5_pulgadas.jpg',
          thumbnail_1: 'assets/images/fotos/Tijera_Las_Pima_de_5-5_pulgadas.jpg', 
          thumbnail_2: 'assets/images/fotos/Tijera_Las_Pima_de_8-5_pulgadas.jpg', 
          thumbnail_3: ''
        },
        { 
          id: 11, 
          nombre: 'Hilaza Nylon Elastica', 
          categoria: 'Hilaza', 
          medida:'No aplica', 
          calibre:'No aplica', 
          gramaje:'100gr ,200gr , 500gr',  
          caracteristicas:'1 y 2 cabos', 
          colores:'toda la gama de colores',
          image:'assets/images/fotos/hilaza_nylon_elastica_200_gramos_blanca_cruda.jpg',
          thumbnail:'assets/images/miniaturas/hilaza_nylon_elastica_200_gramos_blanca_cruda.jpg',
          thumbnail_1: 'assets/images/fotos/hilaza_nylon_elastica_200_gramos_color.jpg', 
          thumbnail_2: 'assets/images/fotos/hilaza_nylon_elastica_200 gramos_color_2.jpg', 
          thumbnail_3: 'assets/images/fotos/hilaza_nylon_elastica_200_gramos_blanca_cruda.jpg'
        },
        { 
          id: 12, 
          nombre: 'Hilaza Poliester por Torta ', 
          categoria: 'Hilaza', 
          medida:'No aplica', 
          calibre:'No aplica', 
          gramaje:'4 kilos aproximadamente',  
          caracteristicas:'1 y 2 cabos', 
          colores:'toda la gama de colores',
          image:'assets/images/fotos/hilaza_poliester_por_torta_en_1cabo_y_2_cabos.jpg',
          thumbnail:'assets/images/miniaturas/hilaza_poliester_por_torta_en_1cabo_y_2_cabos.jpg',
          thumbnail_1: '', 
          thumbnail_2: '', 
          thumbnail_3: ''
        },
        { 
          id: 13, 
          nombre: 'Hilaza Poliester por Torta', 
          categoria: 'Hilaza', 
          medida:'No aplica', 
          calibre:'No aplica', 
          gramaje:'4 kilos aproximadamente',  
          caracteristicas:'1 y 2 cabos', 
          colores:'toda la gama de colores',
          image:'assets/images/fotos/hilaza_nylon_elastica_200_gramos_blanca_cruda.jpg',
          thumbnail:'assets/images/miniaturas/hilaza_poliester_por_torta_en_1cabo_y_2_cabos.jpg',
          thumbnail_1: 'assets/images/fotos/hilaza_poliester_por_torta_en_1cabo_y_2_cabos.jpg', 
          thumbnail_2: '', 
          thumbnail_3: ''
        },
        { 
          id: 14, 
          nombre: 'Hilaza Poliester por Kilo', 
          categoria: 'Hilaza', 
          medida:'No aplica', 
          calibre:'No aplica', 
          gramaje:'1 kilo',  
          caracteristicas:'1 y 2 cabos', 
          colores:'toda la gama de colores',
          image:'assets/images/fotos/hilaza_por_kilo_en_1_cabo_y_2_cabos.jpg',
          thumbnail:'assets/images/miniaturas/hilaza_por_kilo_en_1_cabo_y_2_cabos.jpg',
          thumbnail_1: 'assets/images/fotos/hilaza_por_kilo_en_1_cabo_y_2_cabos.jpg', 
          thumbnail_2: '', 
          thumbnail_3: ''
        },
        { 
          id: 15, 
          nombre: 'Hilaza Poliester x 200 gramos', 
          categoria: 'Hilaza', 
          medida:'No aplica', 
          calibre:'No aplica', 
          gramaje:'200 gramos',  
          caracteristicas:'1 y 2 cabos', 
          colores:'toda la gama de colores',
          image:'assets/images/fotos/hilaza_poliester_200_gramos_en_1_cabo_y_2_cabos.jpg',
          thumbnail:'assets/images/miniaturas/hilaza_poliester_200_gramos_en_1_cabo_y_2_cabos.jpg',
          thumbnail_1: 'assets/images/fotos/hilaza_poliester_200_gramos_en_1_cabo_y_2_cabos.jpg', 
          thumbnail_2: '', 
          thumbnail_3: ''
        },
        { 
          id: 16, 
          nombre: 'Hilaza Poliester x 300 gramos', 
          categoria: 'Hilaza', 
          medida:'No aplica', 
          calibre:'No aplica', 
          gramaje:'300 gramos',  
          caracteristicas:'1 y 2 cabos', 
          colores:'toda la gama de colores',
          image:'assets/images/fotos/hilaza_poliester_300_gramos_1_cabo_y_2_cabos.jpg',
          thumbnail:'assets/images/miniaturas/hilaza_poliester_300_gramos_1_cabo_y_2_cabos.jpg',
          thumbnail_1: 'assets/images/fotos/hilaza_poliester_300_gramos_1_cabo_y_2_cabos.jpg', 
          thumbnail_2: '', 
          thumbnail_3: ''
        },
        { 
          id: 17, 
          nombre: 'Hilaza Poliester x 500 gramos', 
          categoria: 'Hilaza', 
          medida:'No aplica', 
          calibre:'No aplica', 
          gramaje:'500 gramos',  
          caracteristicas:'1 y 2 cabos', 
          colores:'toda la gama de colores',
          image:'assets/images/fotos/hilaza_poliester_500_gramos_en_1_cabo_y_2_cabos.jpg',
          thumbnail:'assets/images/miniaturas/hilaza_poliester_500_gramos_en_1_cabo_y_2_cabos.jpg',
          thumbnail_1: 'assets/images/fotos/hilaza_poliester_500_gramos_en_1_cabo_y_2_cabos.jpg', 
          thumbnail_2: 'assets/images/fotos/hilaza_poliester_500_gramos_1_cabo_y_2 cabos_2.jpg', 
          thumbnail_3: ''
        },
        { 
          id: 18, 
          nombre: 'Hilo calibre 120 en 2.000 yardas', 
          categoria: 'Hilo', 
          medida:'2000 Yardas, 5000 Yardas, 10.000 Yardas', 
          calibre:'120, 75, 50, 30', 
          gramaje:'No aplica',  
          caracteristicas:'1 y 2 cabos', 
          colores:'toda la gama de colores',
          image:'assets/images/fotos/hilo_calibre_120_en_2000_yardas.jpg',
          thumbnail:'assets/images/fotos/hilo_calibre_120_en_2000_yardas.jpg',
          thumbnail_1: 'assets/images/fotos/hilo_calibre_120_en_2000_yardas.jpg', 
          thumbnail_2: '', 
          thumbnail_3: ''
        },

        { 
          id: 19, 
          nombre: 'Hilo calibre 120 en 5.000 yardas', 
          categoria: 'Hilo', 
          medida:'2000 Yardas, 5000 Yardas, 10.000 Yardas', 
          calibre:'120, 75, 50, 30', 
          gramaje:'No aplica',  
          caracteristicas:'1 y 2 cabos', 
          colores:'toda la gama de colores',
          image:'assets/images/fotos/hilo_calibre_120_5000_yardas.jpg',
          thumbnail:'assets/images/miniaturas/hilo_calibre_120_5000_yardas.jpg',
          thumbnail_1: 'assets/images/fotos/hilo_calibre_120_5000_yardas.jpg', 
          thumbnail_2: 'assets/images/fotos/hilo_calibre_120_5000_yardas_2.jpg', 
          thumbnail_3: ''
        },
        { 
          id: 20, 
          nombre: 'Hilo calibre 120 en 10.000 yardas', 
          categoria: 'Hilo', 
          medida:'2000 Yardas, 5000 Yardas, 10.000 Yardas', 
          calibre:'120, 75, 50, 30', 
          gramaje:'No aplica',  
          caracteristicas:'1 y 2 cabos', 
          colores:'toda la gama de colores',
          image:'assets/images/fotos/hilo_calibre_120_10000_yardas.jpg',
          thumbnail:'assets/images/miniaturas/hilo_calibre_120_10000_yardas.jpg',
          thumbnail_1: 'assets/images/fotos/hilo_calibre_120_10000_yardas.jpg', 
          thumbnail_2: 'assets/images/fotos/hilo_calibre_120_10000_yardas_2.jpg', 
          thumbnail_3: ''
        },
        
      ],
      
      
    }
  },
    methods: {
      updateProduct: function(thumbnail){
        for(var i= 0; i < this.productos.length; i++){
        this.productos[i].image = thumbnail
        }
      }
    }
  
  ,computed: {
    currentRouteName() {
        return this.$route.params.id;
    }
    }

  
}


</script>